import { markPoint } from 'public/src/services/mark/index.js'
import { preloadImg } from 'public/src/pages/ccc-campaigns/components/common/utils.js'

async function invokePreloadImg(imgs = []) {
    if (!Array.isArray(imgs) || !imgs?.length) return
    const MARK_IMG_NAME = 'ImgPreloadSpaHydration' // 采集图片预加载的时间
    const jsRunTime = Date.now()
    markPoint({ eventName: MARK_IMG_NAME, tag: 'begin', jsRunTime } )
    try {
      await preloadImg([...new Set(imgs)])
    } catch(e) {
      console.error('invokePreloadImg error:', e)
    } finally {
      markPoint({ eventName: MARK_IMG_NAME, tag: 'end', jsRunTime } )
    }
}

function handleSpaSsrFetchData(result = {}) {
  const SSR_ATTR = 'data-server-rendered'
  const { appendedSelector, ssrHTML, ssrSuiCSS, ssrVueCSS } = result || {}

  // if (ssrSuiCSS && appendedSelector) {
  //   document.querySelector('#ssrSuiCSS').textContent += ssrSuiCSS
  //   // 用于sui的ssr渲染
  //   window.gbSuiSsrData = [... new Set((window.gbSuiSsrData || []).concat(appendedSelector))] 
  // }
  // if (ssrVueCSS) {
  //   document.querySelector('#prerender-css-products').innerHTML = ssrVueCSS
  // }
  const ssrHTMLFragment = document.createRange().createContextualFragment(ssrHTML) // 创建文档片段
  const ssrHTMLFragmentEl = ssrHTMLFragment.querySelector('#product-list-v2')
  if (ssrHTMLFragmentEl) {
    document.querySelector('#prerender-in-app').appendChild(ssrHTMLFragmentEl)
    const _el = document.querySelector('#prerender-in-app #product-list-v2')
    _el.setAttribute(SSR_ATTR, true) // 标记为ssr渲染的dom vue2内部使用
    return _el
  }
}

/**
 * 安卓手机和苹果8机型开启spa水合
*/
function isOpenSpaSsrApp() {
  const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  const res = !isIos || isIpone8()
  isOpenSpaSsrApp = function() {
    return res
  }
  return res
}

// 判断是否是苹果11以下的机型
function isIpone8() {
  // 只要宽度为375就是开启策略
  const fontSize = window.getComputedStyle(document.querySelector('html')).fontSize
  return fontSize === '37.5px'
}

export { invokePreloadImg, handleSpaSsrFetchData, isOpenSpaSsrApp }
