import dayjs from 'dayjs'
import { transformPromodiscountUrl } from 'public/src/pages/common/biz_helper/shein_picks'
import { transformImg } from '@shein/common-function'
import { getSaJumpTypeNew } from 'public/src/pages/common/analysis/ccc_config_analysis.js'

const userPathReplaceSpeCha = function (str) {
  return typeof str === 'string' && str.replace(/\s*[#%&]\s*/g, '-')
}

const userPathGetTitle = function (url) {
  const vscReg = /([^\/]+)-[vs]?c-\d+\.html/
  const promoReg = /\/?(promo-discount|[a-z]+-picks|romwe-[a-z]+)\.html/
  const dailyReg = /\/?daily-new\.html.*daily=(\d+-\d+-\d+)/
  if (vscReg.test(url)) return url.match(vscReg)[1]
  else if (promoReg.test(url)) return url.match(promoReg)[1]
  else if (dailyReg.test(url)) return url.match(dailyReg)[1]
  else if (/\/?daily-new\.html/.test(url)) return 'whats new'
  else return ''
}

export default {
  // common fun, define in  public/src/pages/common/variable
  transformImg,
  channel: 0,
  oneCate: 0,
  userpathParams: {
    channelName: '',
    oneCateName: '',
    cateTitle: ''
  },

  catId: '',

  setData (ObjData) {
    !!ObjData && ObjData instanceof Object && Object.assign(this, ObjData)
  },

  formathTowCategoryData ({ twoCate = [], state = {}, dailyObj = {} } = {}) {
    let position = 0
    return twoCate?.map((item) => {
      const newItem = Object.assign({}, item)
      
      if (!newItem['thumb']) {
        this.userpathParams.cateTitle = newItem['name']
        newItem['position'] = position++
        const { locationInfo, analysisInfo, newCateAlt } = this.formatInfoByHrefType({ cate: newItem, state, dailyObj })
        newItem.alt = newCateAlt
        newItem['locationInfo'] = locationInfo
        newItem['analysisInfo'] = analysisInfo
        return newItem
      }

      if (newItem.type === '7') {
        ++position
      }

      newItem['thumb'] = newItem['thumb'].map((thumb, index) => {
        const itemThumb = Object.assign({}, thumb)

        if (itemThumb.type === '7') {
          itemThumb['position'] = `${position}_${index + 1}`
        } else {
          itemThumb['position'] = position++
        }

        const { locationInfo, analysisInfo, newCateAlt } = this.formatInfoByHrefType({ cate: itemThumb, index, state, dailyObj })

        itemThumb.alt = newCateAlt
        itemThumb['locationInfo'] = locationInfo
        itemThumb['analysisInfo'] = analysisInfo
        itemThumb._target = !!itemThumb.target && this.transformImg({ img: itemThumb.target })

        return itemThumb
      })

      return newItem
    })
  },
  // 新接口二级分类数据
  formathTowCategoryDataNew({ twoCate = [], state = {}, dailyObj = {}, firstFloorContent = {} } = {}) {
    let position = 0
    const data = Array.isArray(twoCate) ? twoCate : twoCate.contents
    return data?.map((item, index) => {
      const newItem = Object.assign({}, item)
      const titleType = newItem?.props?.metaData?.categoryType || {}
      if (newItem?.props?.metaData?.categoryType) {
        newItem.props.metaData.categoryType['position'] = position++
      }
      const { locationInfo, analysisInfo, newCateAlt } =
      this.formatInfoByHrefType({
        cate: titleType,
        index,
        state,
        dailyObj,
      })
      if (newItem?.props?.metaData) {
        newItem.props.metaData.alt = newCateAlt
        newItem.props.metaData['locationInfo'] = locationInfo
        newItem.props.metaData['analysisInfo'] = analysisInfo
      }        
      if (!newItem?.child?.[0]) { return newItem }
      this.userpathParams.cateTitle = newItem?.child[0].props?.['name'] || ''
      // 标题数据
      newItem.firstFloorContent = firstFloorContent
      // 目前ccc 后台只会配置一种分类图，child 数组中只会有一项
      const { props } = newItem?.child[0]
      if (!props)  return newItem
      props.items = props?.items?.map((child, index) => {
        const itemThumb = Object.assign({}, child)
        if(itemThumb.categoryType) {
          itemThumb.categoryType['position'] = position++
        }
        const { locationInfo, analysisInfo, newCateAlt } =
        this.formatInfoByHrefType({
          cate: itemThumb.categoryType || {},
          index,
          state,
          dailyObj,
        })
        itemThumb.alt = newCateAlt
        itemThumb['locationInfo'] = locationInfo
        itemThumb['analysisInfo'] = analysisInfo
        if (itemThumb?.target) {
          itemThumb._target = !!itemThumb?.target && transformImg({ img: itemThumb?.target })
        } else {
          itemThumb._target = !!itemThumb?.cover?.src && transformImg({ img: itemThumb?.cover?.src })
        }
        return itemThumb
      })

      return newItem
    })
  },

  formatInfoByHrefType ({ cate, index, state, dailyObj = {} } = {}) {
    const locationInfo = { hrefType: cate?.hrefType, jumpType: 'redirect', url: '', query: '' }
    const analysisInfo = { categoryIndex: '', target: '', saInfo: '', gaInfo: '', saContent: '', webLink: false }
    const PICKS_LINKS = state.locals?.PICKS_LINKS
    let langPath = state.locals?.langPath
    let IS_RW = state.locals?.IS_RW

    let cateTarget = null
    let newCateAlt = cate.alt
    switch (cate?.hrefType) {
      case 'noJump':
        analysisInfo['categoryIndex'] = '-'
        analysisInfo['target'] = '-'
        analysisInfo['gaInfo'] = '-`'
        break
      case 'real':
        analysisInfo['categoryIndex'] = '1'
        analysisInfo['target'] = cate.hrefTarget
        analysisInfo['gaInfo'] = `真实分类-\`${analysisInfo['target']}`
        locationInfo['jumpType'] = 'router'
        locationInfo['url'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
        if (!newCateAlt) {
          newCateAlt = userPathGetTitle(cate.relativeUrl || cate.catUrl)
        }
        break
      case 'itemPicking':
        analysisInfo['categoryIndex'] = '4'
        analysisInfo['target'] = cate.hrefTarget
        analysisInfo['gaInfo'] = `选品页-\`${analysisInfo['target']}`
        locationInfo['jumpType'] = 'router'
        locationInfo['url'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
        if (!newCateAlt) {
          newCateAlt = userPathGetTitle(cate.relativeUrl || cate.catUrl)
        }
        break
      case 'whatsNew':
      case 'whatsnew':
        cateTarget = this.getCategoryParam({ categoryType: cate.categoryType, categoryList: cate.categoryList, state })
        analysisInfo['categoryIndex'] = '3'
        analysisInfo['target'] = 'whats_new'
        analysisInfo['gaInfo'] = `特殊分类-\`${cateTarget || 'whats new'}`
        locationInfo['jumpType'] = 'router'
        locationInfo['url'] = `${langPath}/daily-new.html`
        locationInfo['query'] = `cat_ids=${cateTarget}`
        if (!newCateAlt) {
          newCateAlt = 'whats new'
        }
        break
      case 'dailyNew': {
        const dailyKey = Object.keys(dailyObj)[index]

        // 当天或昨天的日期使用多语言文案描述
        const time = dayjs(Date.now())
        const today = time.format('YYYY-MM-DD')
        const yesterday = time.add(-1, 'days').format('YYYY-MM-DD')

        if (dailyKey === today) {
          dailyObj[dailyKey] = state.language.SHEIN_KEY_PWA_16288 // Today 多语言文案
        } else if (dailyKey === yesterday) {
          dailyObj[dailyKey] = state.language.SHEIN_KEY_PWA_15855 // Yesterday 多语言文案
        }

        newCateAlt = dailyObj[dailyKey]
        analysisInfo['categoryIndex'] = '3'
        analysisInfo['target'] = 'daily_new'
        analysisInfo['gaInfo'] = '特殊分类-`daily new'
        locationInfo['jumpType'] = 'router'
        locationInfo['url'] = `${langPath}/daily-new.html?daily=${dailyKey}` // 列表页默认分类为 What's New 因此 child_cat_id 设置 0
        locationInfo['query'] = `cat_ids=${this.catId}`
        break
      }
      case 'promoDiscount':
      case 'discountList':
        analysisInfo['categoryIndex'] = '3'
        analysisInfo['target'] = IS_RW ? 'discount_list' : 'shein_picks'
        analysisInfo['gaInfo'] = IS_RW ? '特殊分类-`DiscountList' : '特殊分类-`SheinPicks'
        locationInfo['jumpType'] = 'router'
        locationInfo['url'] = transformPromodiscountUrl(`${langPath}/shein-picks.html` + (cate.hrefTarget ? `?hrefTarget=${cate.hrefTarget}` : ''), { PICKS_LINKS, IS_RW })

        // 项目兼容代码
        if (!newCateAlt) {
          // cate.alt = IS_RW ? 'promo-discount' : 'shein-picks'
          newCateAlt = IS_RW ? 'promo-discount' : 'shein-picks'
        }
        break
      case 'game':
        analysisInfo['categoryIndex'] = '7'
        analysisInfo['target'] = cate.hrefTarget
        analysisInfo['gaInfo'] = `链接-\`${cate.hrefTarget}`
        locationInfo['url'] = `${langPath}/${cate.gameType}/${cate.hrefTarget}`
        locationInfo['jumpType'] = 'redirect'
        break
      case 'activity':
      // case 'game':
        analysisInfo['categoryIndex'] = '5'
        analysisInfo['target'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
        analysisInfo['gaInfo'] = `campaign-\`${cate.hrefTarget}`
        locationInfo['url'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
        // 专题跳转修改为单页面跳转
        locationInfo['jumpType'] = 'router'
        break
      case 'activityOld':
        // case 'game':
        analysisInfo['categoryIndex'] = '5'
        analysisInfo['target'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
        analysisInfo['gaInfo'] = `campaign-\`${cate.hrefTarget}`
        locationInfo['url'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
        // 专题跳转修改为单页面跳转
        locationInfo['jumpType'] = 'router'
        break
      // 跳转新专题
      case 'activityNew':
        analysisInfo['categoryIndex'] = '12'
        analysisInfo['target'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
        analysisInfo['gaInfo'] = `campaigns-\`${cate.hrefTarget}`
        locationInfo['url'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
        // 专题跳转修改为单页面跳转
        locationInfo['jumpType'] = 'router'
        break
      case 'flashSale':
        analysisInfo['categoryIndex'] = '6'
        analysisInfo['target'] = 'flash_sale'
        analysisInfo['gaInfo'] = 'FalshSale-`flash sale'
        locationInfo['url'] = `${langPath}/flash-sale.html`
        locationInfo['query'] = `cat_ids=${this.getCategoryParam({ categoryType: cate.categoryType, categoryList: cate.categoryList, state })}`
        locationInfo['jumpType'] = 'router'
        break
      case 'giftCard':
        analysisInfo['categoryIndex'] = '8'
        analysisInfo['target'] = 'giftcard'
        locationInfo['url'] = `${langPath}/giftcard.html`
        analysisInfo['gaInfo'] = '链接-`gift card'
        locationInfo['jumpType'] = 'redirect'
        break
      case 'webLink':
        analysisInfo['categoryIndex'] = '7'
        analysisInfo['target'] = cate.hrefTarget
        analysisInfo['gaInfo'] = `链接-\`${cate.hrefTarget}`
        locationInfo['url'] = cate.hrefTarget
        locationInfo['webLink'] = true
        break
      case 'lookBook':
        analysisInfo['categoryIndex'] = '10'
        analysisInfo['target'] = 'lookBook'
        locationInfo['url'] = `${langPath}/shop-multiple-sets-${cate.hrefTarget}.html`
        analysisInfo['gaInfo'] = '特殊分类-`lookBook'
        locationInfo['jumpType'] = 'router'
        break
      case 'article':
        analysisInfo['categoryIndex'] = '10'
        analysisInfo['target'] = 'article'
        locationInfo['url'] = `${langPath}/${cate.selectTypeName || 'article'}-a-${cate.hrefTarget}.html`
        analysisInfo['gaInfo'] = '链接-`article'
        locationInfo['jumpType'] = 'redirect'
        break
      case 'trial':
        analysisInfo['categoryIndex'] = '9'
        analysisInfo['target'] = 'trial'
        locationInfo['url'] = `${langPath}/free-trial-center/index`
        analysisInfo['gaInfo'] = '链接-`trial'
        locationInfo['jumpType'] = 'router'
        break
      case 'prime':
        analysisInfo['categoryIndex'] = '11'
        analysisInfo['target'] = 'prime'
        locationInfo['url'] = `${langPath}/user/prime`
        analysisInfo['gaInfo'] = '链接-`prime'
        locationInfo['jumpType'] = 'router'
        break
      case 'sheinxDesignerInformation':
        analysisInfo['categoryIndex'] = '12'
        analysisInfo['target'] = cate.hrefTarget
        locationInfo['url'] = `${langPath}/designer/${cate.hrefTarget}`
        analysisInfo['gaInfo'] = '链接-`sheinxDesignerInformation'
        locationInfo['jumpType'] = 'router'
        break
      case 'sheinxDesignerList':
        analysisInfo['categoryIndex'] = '13'
        analysisInfo['target'] = 'sheinxDesignerList'
        locationInfo['url'] = `${langPath}/designer_list`
        analysisInfo['gaInfo'] = '链接-`sheinxDesignerList'
        locationInfo['jumpType'] = 'redirect'
        break
      default:
        analysisInfo['gaInfo'] = '-`'
    }

    analysisInfo['saInfo'] = `${String(cate.position).indexOf('_') > -1 ? cate.position : Number(cate.position) + 1}\`${analysisInfo['categoryIndex'] || '-'}\`${analysisInfo['target'] || '-'}`

    analysisInfo['saContent'] = getSaJumpTypeNew(cate)
    locationInfo['query'] += cate.adp ? `&adp=${cate.adp}` : ''
    if (['real', 'itemPicking', 'whatsNew', 'dailyNew', 'promoDiscount'].includes(cate.hrefType)) {
      const { channelName, oneCateName, cateTitle } = this.userpathParams
      let userpath = `>${channelName}>${oneCateName}>`
      if (cate.type == 2) userpath += cateTitle
      else if (cateTitle) userpath += `${cateTitle}>${newCateAlt}`
      else userpath += newCateAlt
      locationInfo['userpath'] = userPathReplaceSpeCha(userpath)
      locationInfo['userpathFrom'] = { cate: state.language.SHEIN_KEY_PWA_14980, shop: state.language.SHEIN_KEY_PWA_14979 }
    }

    newCateAlt = typeof newCateAlt === 'string' ? newCateAlt.trim() : ''

    return {
      locationInfo,
      analysisInfo,
      newCateAlt
    }
  },

  getCategoryParam ({ categoryType = 0, categoryList = [], state = {} } = {}) {
    let IS_RW = state.locals.IS_RW
    const catIdsArray = []
    categoryList && categoryList.forEach(item => {
      if (categoryType == '2' && item.val) {
        catIdsArray.push(IS_RW ? item.romwe_cat_id : item.shein_cat_id)
      }
    })
    return catIdsArray.length ? decodeURIComponent(catIdsArray.join(',')) : ''
  },

  storageHandle ({ methods, key = undefined, value = undefined, storageType = 'sessionStorage' } = {}) {
    if (typeof window == 'undefined') return
    switch(methods) {
      case 'get':
        if (key === 'undefined') {
          console.error('[category] miss storageHandle key')
          return
        }
        if (storageType === 'sessionStorage') return sessionStorage.getItem(key) // null
        break
      case 'set':
        if (key === 'undefined' || value === 'undefined') {
          console.error('[category] miss storageHandle key or value')
          return
        }
        if (storageType === 'sessionStorage') return sessionStorage.setItem(key, value)
        break
    }
  },
  cacheSelectedIndex ({ channel = '', oneCate = '', channelKey, oneCategoryKey, storageType = 'sessionStorage' } = {}) {
    channel !== '' && this.storageHandle({ methods: 'set', key: channelKey, value: channel, storageType })
    oneCate !== '' && this.storageHandle({ methods: 'set', key: oneCategoryKey, value: oneCate, storageType })
  },
  getTwoImgThumb ({ cate, lineIndex = 0, offset = 0 } = {}) {
    return cate.thumb[lineIndex * 2 + offset]
  },
  getTwoImgThumbWidth ({ cate, lineIndex }) {
    let width = 0
    if (this.getTwoImgThumb({ cate, lineIndex })) width = Number(this.getTwoImgThumb({ cate, lineIndex }).width)
    if (this.getTwoImgThumb({ cate, lineIndex, offset: 1 })) width = width + Number(this.getTwoImgThumb({ cate, lineIndex, offset: 1 }).width)
    return width
  },
}
