import service from './service'
import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'

/**
 * @description 格式化分类数据
 * @param {Array} data - 分类数据
 * @returns {Object} { categoryData, categoryDataListByOrder } - 分类数据对象
 * */
export function formatCategoryData(data) {
  if (!Array.isArray(data)) {
    console.error('formatCategoryData: data is not an array')
  }

  const categoryData = {}
  const categoryDataListByOrder = {}

  data.forEach((item, index) => {
    categoryDataListByOrder[index] = item
    categoryData[item.channel_type] = {
      ...(item || {}),
      index,
    }
  })

  return { categoryData, categoryDataListByOrder }
}

/**
 * @description 获取当前type存储的key
 * @param {String} cateType - 分类类型
 * @param {String} localsLang - 语言
 * @returns {Object} { channelKey, oneCategoryKey, storageType } - 存储的key
 * */
export function cateHomeStorageData({ localsLang, cateType }) {
  if (!cateType) {
    throw new Error('cateHomeStorageData: cateType is not defined')
  }
  if (!localsLang) {
    throw new Error('cateHomeStorageData: localsLang is not defined')
  }
  if (cateType === 'cat') {
    return {  }
  } else {
    return {
      channelKey: `${localsLang}-pwa-sidebar-channel`,
      oneCategoryKey: `${localsLang}-pwa-sidebar-one-level-index`,
      storageType: 'sessionStorage',
    }
  }
}

/**
 * @description 获取当前分类选中的索引
 * @returns {Object} { channel, oneCategoryIndex } - 当前分类选中的索引
 */
export function getInitActiveInfoIndex(
  { state = {} },
  { channelKey, oneCategoryKey, storageType, queryOneCate, queryChannel }
) {
  if (typeof window === 'undefined') {
    console.error('getInitActiveInfo: window is not defined')
    return {}
  }

  const { categoryData, crowdChannel, dynamicCates, isPreview } = state

  let cacheChannel = storageType && service.storageHandle({
    methods: 'get',
    key: channelKey,
    storageType,
  })

  let channel = queryChannel || crowdChannel || cacheChannel

  let oneCategoryIndex = 0

  if (queryChannel) {
    oneCategoryIndex = queryOneCate || 0
  } else if (crowdChannel) {
    oneCategoryIndex = 0
  } else if (cacheChannel) {
    oneCategoryIndex =
      service.storageHandle({
        methods: 'get',
        key: oneCategoryKey,
        storageType,
      }) || 0
  }

  // 兜底容错
  if (!categoryData[channel]) {
    channel = dynamicCates?.[0]?.['channel_type'] || 2
    if (!isPreview) {
      // 预览模式下不兜底
      oneCategoryIndex = 0
    }
  }

  return {
    channel,
    oneCategoryIndex,
  }
}

/**
 * @description 设置缓存数据
 * @param {Object} categoryTypeData - 当前类型的分类数据
 * */
export function setInitCacheData(categoryTypeData) {
  if (typeof window === 'undefined') return
  const channelKey = categoryTypeData.channelKey 
  if (channelKey) {
    const { channel, oneCate } = categoryTypeData.active || {}
    service.cacheSelectedIndex({
      channel,
      oneCate,
      channelKey,
      oneCategoryKey: categoryTypeData.oneCategoryKey,
      storageType: categoryTypeData.storageType,
    })
  }
}

/**
 * @description 获取一级分类和能展开的分类数据
 * @param {Object} categoryData - 分类数据
 * @param {String} channel - 当前分类类型id
 * @returns {Object} { oneCategory, oneCategoryExpand } - 一级分类和能展开的分类数据
 */
export function getOneCategoryOrExpand({ channel, categoryData }) {
  const oneCategory = categoryData[channel]?.child || []
  const oneCategoryExpand = {}

  oneCategory?.forEach(item => {
    if (item.isFatherFirstTitle) {
      oneCategoryExpand[item.id] = [
        ...oneCategory.filter(el => el.titleParentId == item.id),
      ]
    }
  })

  return {
    oneCategory,
    oneCategoryExpand,
  }
}

export function getOriginTwoCategoryInfo({ categoryData, channel, oneCate }) {
  const currentChannel = categoryData?.[channel] || {}
  const oneCategory = currentChannel.child || []
  const oneCategoryItem = oneCategory[oneCate] || {}
  const twoCategory = oneCategoryItem.child || []
  const firstFloorContent = oneCategoryItem.firstFloorContent || {}

  return { twoCategory, currentChannel, oneCategoryItem, firstFloorContent }
}

function formatTwoCategoryData({ data, cateExpand, rightCateWidth }) {
  return data?.map(cate => {
    // 处理expand
    let find
    if (cate.parentName) {
      find = data.find(e =>
        cate.secondTitleId
          ? e && e.id == cate.secondTitleId
          : e && e.name == cate.parentName
      )
      const type = find?.style?.type
      find.expandChild = cate
      cate.setParent = true
      // 第一次进来
      find.show =
        find.show ||
        (type === 'TEXT_EXTENSIBLE' || type === 'IMAGE_TEXT_EXTENSIBLE'
          ? false
          : true)
    }
    cate.parentCatNode = find && { style: find.style }
    cate.expand = cateExpand?.[cate.id] || false
    // 兼容第二次进来
    cate.show = cate.expand || cateExpand?.[cate.id]

    if (cate && (cate.type == 3 || cate.type == 7)) {
      cate.thumb.forEach(thumb => {
        thumb.realWidth = `${rightCateWidth}rem`
        thumb.realHeight = `${
          (Number(thumb.height) / Number(thumb.width)) * rightCateWidth
        }rem`
      })
    }
    if (cate && cate.type == 4) {
      new Array(Math.ceil(cate.thumb.length / 2))
        .fill('')
        .forEach((val, lineIndex) => {
          const thumb = service.getTwoImgThumb({ cate, lineIndex })
          const totalWidth = service.getTwoImgThumbWidth({ cate, lineIndex })
          const realWidth = (thumb.width / totalWidth) * rightCateWidth
          thumb.realWidth = `${realWidth}rem`
          thumb.realHeight = `${
            (Number(thumb.height) / Number(thumb.width)) * realWidth
          }rem`
        })
    }

    return cate
  })
}

export function getTwoCategoryData(
  { locals, language },
  { dynamicCates, categoryData, channel, oneCate, cateExpand, rightCateWidth, isNew }
) {
  if (!locals) {
    throw new Error('getTwoCategoryData: locals is not defined')
  }
  if (!language) {
    throw new Error('getTwoCategoryData: language is not defined')
  }
  const { twoCategory, currentChannel, oneCategoryItem, firstFloorContent } =
    getOriginTwoCategoryInfo({ categoryData, channel, oneCate })
  const channelName = currentChannel.name

  const oneCateName = oneCategoryItem.name ||  oneCategoryItem?.firstFloorContent?.title || ''
  const dailyObj = dynamicCates?.[0]?.dailyObj || {}

  service.setData({
    channel,
    oneCate,
    userpathParams: { channelName, oneCateName, cateTitle: '' },
    catId: currentChannel.cat_id,
  })
  // 修改 加个标识，减少format次数
  if (isNew && !oneCategoryItem.formatData) {
    const originData = service.formathTowCategoryDataNew({
      twoCate: twoCategory,
      firstFloorContent,
      state: { locals, language },
      dailyObj,
    })
    oneCategoryItem.formatData = originData
    return oneCategoryItem.formatData
  }
  // 当前二级分类是否已经格式化过, 没有格式化过进行格式化
  if (!oneCategoryItem.formatData && !isNew) {
    const originData = service.formathTowCategoryData({
      twoCate: twoCategory,
      state: { locals, language },
      dailyObj,
    })
    oneCategoryItem.formatData = originData
  }

  oneCategoryItem.child = formatTwoCategoryData({
    data: oneCategoryItem.formatData, 
    cateExpand,
    rightCateWidth
  })
  return oneCategoryItem.child
}

/**
 * @description 获取一级和二级分类数据
 * @returns {Object} { oneCategoryExpand, oneCategory, twoCategory } - 一级和二级分类数据 
*/
export function getCategoryChild({ channel, categoryData, dynamicCates, locals, language, oneCate, cateExpand, rightCateWidth, isNew }) {  
  /* 获取一级分类数据 */ 
  const { oneCategoryExpand, oneCategory } = getOneCategoryOrExpand({ channel, categoryData })

  /* 获取二级分类数据 */ 
  const twoCategory = getTwoCategoryData(
    { locals, language },
    {
      dynamicCates,
      categoryData,
      channel,
      oneCate,
      cateExpand,
      rightCateWidth,
      isNew
    }
  )
  return { oneCategoryExpand, oneCategory, twoCategory }
}

/**
 * @description 获取默认展开的一级分类
 * @param {Object} oneCategory - 一级分类数据
 * @param {Object} allTabAbt - abt配置
 * @param {Number} oneCate - 当前一级分类索引
 * @returns {Object} { expandRearFirstLevel, expandFirstLevelId } - 默认展开的一级分类id
*/
export function getDefaultExpandFirstLevel({ oneCategory, /* allTabAbt = {},*/ oneCate }) {
  if (oneCategory.length == 0) {
    return { expandRearFirstLevel: '', expandFirstLevelId: '' }
  }
  let currentExpandFirstLevelId = oneCategory?.[oneCate]?.titleParentId // 有父级, 展开父级
  let currentExpandRearFirstLevel = ''

  // abt配置命中，获取第一个没有child的数据进行展开
  if (!currentExpandFirstLevelId /* && allTabAbt.expandAllTabFirstLevel */) {
    let itDefaultItem = oneCategory.find(item => item.isFatherFirstTitle && !item.child?.length)
    if (itDefaultItem) {
      currentExpandRearFirstLevel = itDefaultItem.id
      currentExpandFirstLevelId = itDefaultItem.id
    }
  }

  return { 
    expandRearFirstLevel: currentExpandRearFirstLevel || '',  
    expandFirstLevelId: currentExpandFirstLevelId || ''
  }
}


export function getUpdateCategoryPageInit({ channel, oneCate, categoryData, dynamicCates, language, /* allTabAbt,*/ locals, cateExpand, rightCateWidth, isNew }) {  
  // 处理一级分类和二级分类数据
  const { oneCategoryExpand, oneCategory, twoCategory } = getCategoryChild({ channel, categoryData, dynamicCates, locals, language, oneCate, cateExpand, rightCateWidth, isNew })
  
  // 一级分类默认展开id配置
  const { expandRearFirstLevel, expandFirstLevelId } = getDefaultExpandFirstLevel({ 
    oneCategory, 
    oneCate,
    // allTabAbt,
  })

  return {
    oneCategoryExpand,
    oneCategory,
    twoCategory,
    expandRearFirstLevel,
    expandFirstLevelId,
  }
}

// 给二级数据增加埋点相关字段信息
export const  handleTwoCategoryData = async (data = [], language = {}) => {
  const { contents = [] } = data
  let item_loc = 0 // 当前位置
  if (contents.length > 0) {
    for (let i = 0; i < contents.length; i++) {
      const item = contents[i]
      const { child, props } = item
      const items = child?.[0]?.props?.items || props?.items || [] // 图片数据返回位置不一样
      // 处理标题节点
      // TODO: 融合后gbCommonInfo 里是否还能拿到数据
      const contentAnalysis = service.formatInfoByHrefType({ 
        cate: Object.assign({}, props?.metaData?.categoryType, { position: item_loc }), 
        state: { locals: gbCommonInfo || {}, language }, 
        index: item_loc 
      })

      props.metaData.contentAnalysis = contentAnalysis
      props.metaData.contentAnalysis.item_loc = item_loc
      item_loc++

      // 处理子节点
      if (items.length > 0) {
        for (let j = 0; j < items.length; j++) {
          const item = items[j]
          // TODO: 融合后gbCommonInfo 里是否还能拿到数据
          const contentAnalysis = service.formatInfoByHrefType({ 
            cate: Object.assign({}, item?.categoryType, { position: item_loc }), 
            state: { locals: gbCommonInfo || {}, language }, 
            index: item_loc 
          }) 

          item.contentAnalysis = contentAnalysis
          item.contentAnalysis.item_loc = item_loc
          item_loc++
        }
      }
    }
  }
}
// 处理右侧埋点数据
export const  handleAnalysisData = ({ state }, { channel, oneCate, rightContentRes }) => {
  const oneCategory = state?.bffCat?.oneCategory
  const channelName = channel?.channelName
  const oneCateName = oneCategory?.contents?.[oneCate]?.firstFloorContent?.props?.metaData?.categoryName
  const language = state.bffLanguages || {}
  service.setData({
    channel,
    oneCate: oneCategory?.contents?.[oneCate],
    userpathParams: { channelName, oneCateName, cateTitle: '' },
    catId: channel?.cat_id,
  })
  handleTwoCategoryData(rightContentRes || [], language)
}
// 处理缓存索引
export const handleCacheIndex = ({ state }, { stateType, cateType, oneCate }) => {
  const beforeStorageData = service.storageHandle({
    methods: 'get',
    key: `${state?.[stateType].storageKey}-${cateType}`,
  }) || JSON.stringify({})
  service.storageHandle({
    methods: 'set',
    key: `${state?.[stateType].storageKey}-${cateType}`,
    value: JSON.stringify(Object.assign({}, JSON.parse(beforeStorageData), { [state?.[stateType]?.activeChannel?.channel?.id]: oneCate }))
  })
}
/**
 * 业务监控
 */
export function setMonitor( { oneCategory = [], twoCategory = [] } ) {
  if (typeof window === 'undefined') return
  metricPageSuccess({ 
    page: 'page_category',
    status: (oneCategory?.length && twoCategory?.length) ? '1' : '0',
  })
}

// SSR时给twoCategory添加fsFlag首屏标识, 暂时不考虑图片导航
export const addFsFlagToCategory = (twoCategory) => {
  // 预知的SSR范围
  const SSR_LAYOUT_HEIGHT = 600
  // 二级类目高度
  const CATE_CLASSIFY_HEIGHT = 80
  // 二级类目标题高度
  const CATE_TITLE_HEIGHT = 30
  let compsHeight = 0
  return twoCategory.map(cate => {
    if (compsHeight > SSR_LAYOUT_HEIGHT) {
      cate.fsFlag = false
      return cate
    }
    const { child, styleType } = cate
    if (styleType !== 'FLOOR_COMPONENT') {
      cate.fsFlag = true
      return cate
    }
    const itemLength = child?.[0]?.props?.items?.length ?? 0

    cate.fsFlag = true
    compsHeight += CATE_TITLE_HEIGHT + Math.ceil(itemLength / 3) * CATE_CLASSIFY_HEIGHT
    return cate
  })
}
