import ClipboardJS from 'clipboard'
import { isFunction } from '@shein/common-function'

/**
 * 预加载图片
 * @param {Array} imgs 
 * @param {Function} callback 
 * @param {Number} timeout
 * @returns 
 */
export const preloadImg = async (imgs, callback, timeout = 3000) => {
  return Promise.race([
    new Promise((resolve) => {
      let loadedNum = 0
      const imgLoadHandle = () => {
        loadedNum++
        if(loadedNum === imgs.length) {
          callback?.()
          resolve()
        }
      }
      imgs.forEach((src) => {
        const img = new Image()
        img.src = src
        img.onload = imgLoadHandle
        img.onerror = imgLoadHandle
      })
    }),
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, timeout)
    })
  ])
}

/**
 * 复制文案到粘贴板
 * @param {String} trigger 触发dom选择器
 * @param {} config 配置
 */
export function useClipboard(trigger, config) {
  const { text, onSuccess, onError } = config

  const clipboard = new ClipboardJS(trigger, {
    text: () => text
  })

  isFunction(onSuccess) && clipboard.on('success', (e) => {
    onSuccess.call(null, e)
    e.clearSelection()
    clipboard.destroy()
  })

  isFunction(onError) && clipboard.on('error', (e) => {
    onError.call(null, e)
    e.clearSelection()
    clipboard.destroy()
  })
}
