export const BANNER_DESIGN_WIDTH = 250
export const CLSSIFY_DESIGN_WIDTH = 132
export const CLSSIFY_MARK_DESIGN_WIDTH = 40
export const SLIDER_LOGIN_DESIGN_WIDTH = 500
export const SLIDER_STACK_DESIGN_WIDTH = 500
export const TITLE_DESIGN_WIDTH = 82
export const TWO_IMAGE_DESIGN_WIDTH = 250
export const SIDE_CONTAINER_DESIGN_WIDTH = 88

export const languageKey = [
  'SHEIN_KEY_PWA_14980',
  'SHEIN_KEY_PWA_15669',
  'SHEIN_KEY_PWA_15668',
  'SHEIN_KEY_PWA_16210',
  'SHEIN_KEY_PWA_17167',
  'SHEIN_KEY_PWA_14981',
  'SHEIN_KEY_PWA_14980',
  'SHEIN_KEY_PWA_14979',
  'SHEIN_KEY_PWA_17501'
]

export const categoryTypes = {
  'SIDE_CAT': 'sidecat',
  'CAT': 'cat',
}
