// 职责: 单页跳转首次预取列表页html
import { handlePointerChange, handleClickChange } from './spaSsrHydration'
import { pointerOverGoodsRequest } from './index.js'
import { markPoint } from 'public/src/services/mark/index.js'

const preloadGoodsRequest = {
  triggerNotice(path) {
    markPoint({ eventName: 'PointerTrigger', measureTo: 'ClickTrigger' })
    handlePointerChange({ path })
    pointerOverGoodsRequest.triggerNotice(path)
  },
  click(path) {
    handleClickChange({ path })
    pointerOverGoodsRequest.click()
    markPoint({ eventName: 'ClickTrigger', measureFrom: 'PointerTrigger' })
  }
}

export {
  preloadGoodsRequest
}
